<style lang="scss">
    @import '~@/assets/css/var';
    .cert {
        .id {
            .van-uploader__wrapper {
                justify-content: center;
            }
            .van-uploader__upload {
                width: 140px;
                height: 120px;
            }
        }

        &-block {
            margin: 15px;
            border-radius: 15px;
            overflow: hidden;
            &__title {
                line-height: 40px;
                color: #fff;
                background: $main;
                text-align: center;
            }
            &__panel {
                padding: 20px 15px 20px 30px;
                background: #fff;
                .van-radio-group{
                    .van-radio__icon--checked .van-icon{
                        background-color: $main;
                        border-color: $main;
                    }
                }

                p {
                    font-size: 12px;
                    line-height: 36px;
                    border-bottom: 1px solid $borderColor;
                    padding-left: 15px;
                    &:first-child {
                        border-top: 1px solid $borderColor;
                    }
                }
                input {
                    width: 100px;
                    border: 0;
                }
                i {
                    display: inline-block;
                    flex-shrink: 0;
                    width: 50px;
                    font-size: 16px;
                }
            }
        }
        .bottom-btn{
            z-index: 888;
            .van-button{
                background: $main;
                border: 0;
            }
        }
        .my-popup{
            background-color: #fff;
            .no_data{
                top: 70%;
            }
            .my-cell{ 
                i{
                    color: $main;
                    padding-right: 5px;
                }
            }
        }
        padding-bottom: 60px;
    }
</style>

<template>
    <div class="cert has-bottom">
        <van-notice-bar text="*平台将保证您的信息安全，银行卡与身份证必须为本人。"/>

        <div class="cert-block">
            <div class="cert-block__title">身份信息</div>
            <div class="cert-block__panel">
                <p class="flex">
                    <van-icon name="user-circle-o" />
                    <input class="flex-item" :readonly="readonly" placeholder="请输入姓名" v-model="form.memberName">
                </p>
                <p class="flex">
                    <i class="icon icon-xingbie1"></i>
                    <van-radio-group v-model="form.sex" direction="horizontal">
                        <van-radio name="1">男</van-radio>
                        <van-radio name="2">女</van-radio>
                    </van-radio-group>
                </p>
                <p class="flex">
                    <van-icon name="phone-o" />
                    <input class="flex-item" :readonly="readonly" placeholder="请输入手机号" v-model="form.mobile">
                </p>
                <p class="flex">
                    <i class="icon icon-minzu"></i>
                    <input class="flex-item" :readonly="readonly" placeholder="请输入民族" v-model="form.nation">
                </p>
                <p class="flex">
                    <van-icon name="idcard" />
                    <input class="flex-item" :readonly="readonly" placeholder="请输入身份证" v-model="form.idCard">
                </p>
                <p class="flex">
                    <van-icon name="clock-o" />
                    <input class="flex-item" readonly placeholder="请选择出生日期" v-model="form.birthDate" @click="showDate=true">
                </p>
                <!-- <p class="flex">
                    <van-icon name="debit-pay" />
                    <input class="flex-item"  placeholder="请输入开户银行" v-model="form.openName">
                </p>
                <p class="flex">
                    <van-icon name="debit-pay" />
                    <input class="flex-item"  placeholder="请输入银行卡号" v-model="form.bankAccount">
                </p> -->
                <p class="flex">
                    <van-icon name="location-o" />
                    <input class="flex-item"  placeholder="请输入住宅地址" v-model="form.address">
                </p>
                <p class="flex">
                    <van-icon name="location-o" />
                    <input class="flex-item" :readonly="readonly" placeholder="请输入收件地址" v-model="form.receivingAddress">
                </p>
            </div>
        </div>

        <my-btn @onSubmit="save" :btnStr="'提交'" ></my-btn>


        <van-popup v-model="showDate" position="bottom" round>
            <van-datetime-picker v-model="currentDate" type="date" title="选择年月日" @confirm="getDate"
                @cancel="showDate = false"
                :min-date="minDate"
                :max-date="maxDate"
            />
        </van-popup>
    </div>
</template>

<script>
    import mixinPage from '@/utils/mixinPage';
    export default {
        mixins: [mixinPage],
        data() {
            return {
                userInfo: {},
                readonly:false,
                logTxt: '长期有效',
                longTerm:'',
                minDate: new Date(1949, 10, 1),
                maxDate: new Date(2100, 0, 31),
                currentDate: '',
                showDate: false,


                params: {},
                form: {
                    memberName: '',
                    radio: '',
                    nation: '',
                    sex: '',
                    idCard: '',
                    birthDate: '',
                    openName: '',
                    bankAccount: '',
                    address: '',
                    receivingAddress: '',
                    mobile: ''
                },
            }
        },
        methods: {
            getDate(value) {
                this.form.birthDate = new Date(value).FormatShort()
                this.showDate = false
            },
            save() {
                this.$set(this.form, 'id', this.userInfo.id)
                console.log(this.form)
                this.$help.loading()
                this.$api.post('member/base/member/member-update', this.form)
                .then(res => {
                    if(res.data) {
                        this.$help.success('修改成功')
                    }else{
                        this.$help.error(res.msg)
                    }
                })
                .finally(this.$help.loaded)
            },
            getMemberUser() { 
                this.$help.loading()
                this.$store.dispatch('getMemberUser').then(() => {
                    this.userInfo = this.$store.state.memberUser
                    for(let i in this.form) {
                        this.form[i] = this.userInfo[i]
                    }
                })
                .finally(this.$help.loaded)
            },
        },
        mounted(){
            this.getMemberUser()
            this.currentDate = new Date()
        }
    }
</script>